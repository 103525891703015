import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";
import unam from "../../Assets/Projects/unam.png";
import brokerelief from "../../Assets/Projects/brokerelief.png";
import ecowise from "../../Assets/Projects/ecowise.png";
import traveltracker from "../../Assets/Projects/traveltracker.png";
import weather from "../../Assets/Projects/weatherApp.png";
import mariental from "../../Assets/Projects/mariental.png";
import cdcm from "../../Assets/Projects/cdcm.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={unam}
              isBlog={false}
              title="Job Portal"
              description="UNAM Job Portal, a dynamic employment hub created with Laravel and PHP, streamlines the job search for graduates and recruitment for employers. Leveraging Blade and Vue.js, it offers real-time job listings, profile customization for job seekers, and efficient candidate screening for organizations."
              ghLink="https://github.com/abanj95/Jobunam"
              demoLink="#"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={brokerelief}
              isBlog={false}
              title="Broke Relief Cash Services"
              description="Broke relief is a web-based loan management application with a built-in wallet for instant borrowing, meticulously engineered using Laravel and PHP."
              ghLink="#"
              demoLink="https://brokerelief.cash"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ecowise}
              isBlog={false}
              title="Ecowise"
              description="Crafted a dynamic website for Eco-Wise Environmental Consulting CC, a leading environmental consulting firm. Built with WordPress and enhanced through custom HTML and CSS, the site effectively showcases their expertise across various sectors including construction, agriculture, and energy, providing an engaging and user-friendly online presence."
              ghLink="#"
              demoLink="https://ecowiseec.com/"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={traveltracker}
              isBlog={false}
              title="Family Travel Tracker"
              description="Designed a travel tracker web application where users can create accounts to mark and track the countries they've visited. Built with Node.js and utilizing EJS for templating, this site offers a personalized and interactive experience for travel enthusiasts, all powered by efficient JavaScript coding on the backend."
              ghLink="https://github.com/abanj95/Travel-tracker-App"
              demoLink="#"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={weather}
              isBlog={false}
              title="Weather App"
              description="A user-friendly weather application leveraging the OpenWeather API to provide real-time weather data. The app features current conditions, forecasts of cities."
              ghLink="https://github.com/abanj95/Weather-App"
              demoLink="#"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={cdcm}
              isBlog={false}
              title="Debt Relief website"
              description="A detailed designed finance industry website built on wordpress integrated with beautiful email templates as well."
              ghLink="#"
              demoLink="https://cdcm.com.na" 
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={mariental}
              isBlog={false}
              title="Mariental Media"
              description="A media company's website to showcase their work and services."
              ghLink="#"
              demoLink="https://marientalmedia.com" 
            />
          </Col>

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Face Recognition and Emotion Detection"
              description="Trained a CNN classifier using 'FER-2013 dataset' with Keras and tensorflow backened. The classifier sucessfully predicted the various types of emotions of human. And the highest accuracy obtained with the model was 60.1%.
              Then used Open-CV to detect the face in an image and then pass the face to the classifer to predict the emotion of a person."
              ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col> */}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
